import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    MeetingProvider,
    useMeeting,
    useParticipant,
} from "@videosdk.live/react-sdk";
import { authToken, createMeeting } from "../API";
import ReactPlayer from "react-player";
import Chat from "./Chat";
import JoinScreen from "./JoinScreen";
import Navbar from "./Navbar";

function ParticipantView(props) {
    const micRef = useRef(null);
    const {
        webcamStream,
        micStream,
        webcamOn,
        micOn,
        isLocal,
        displayName,
        screenShareStream,
        screenShareOn,
    } = useParticipant(props.participantId);

    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    const screenStream = useMemo(() => {
        if (screenShareOn && screenShareStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareStream.track);
            return mediaStream;
        }
    }, [screenShareStream, screenShareOn]);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);
                micRef.current.srcObject = mediaStream;
                micRef.current.play().catch((error) =>
                    console.error("videoElem.current.play() failed", error)
                );
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    return (
        <div className={`border p-2 relative shadow-sm`}>
            <p className="text-gray-900 pb-2 pt-0">
            <span className="bg-green-100 text-green-800 text-xs font-medium me-1 px-1 py-0.5 rounded">{displayName}</span> {webcamOn ? <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-1 py-0.5 rounded">Webcam: ON</span> : <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-1 py-0.5 rounded">Webcam: OFF</span>
                }
                {micOn ? <span className="bg-green-100 text-green-800 text-xs font-medium me-1 px-1 py-0.5 rounded">Mic: ON</span> : <span className="bg-red-100 text-red-800 text-xs font-medium me-1 px-1 py-0.5 rounded">Mic: OFF</span>
                } {screenShareOn ? <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">Screen: ON</span> : <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">Screen:  OFF</span>}
            </p>
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            <ReactPlayer
                playsinline
                pip={false}
                light={false}
                controls={false}
                muted={true}
                playing={true}
                url={screenStream || videoStream}
                height={props?.height ? props?.height : "240px"}
                width="100%"
                onError={(err) => {
                    console.log(err, "participant video error");
                }}
            />
        </div>
    );
}

function Controls() {
    const { leave, toggleMic, toggleWebcam, toggleScreenShare } = useMeeting();

    return (
        <div className="flex flex-wrap justify-center gap-3 mt-20 absolute bottom-[-0%] left-[20%] lg:left-[43%]">
            <button onClick={() => leave()} className="bg-red-600 hover:bg-red-700 text-white font-medium py-2 px-4 mb-2 rounded-md w-25">Leave</button>
            <button onClick={() => toggleMic()} className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-2 mb-2 rounded-md w-25"><svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="9" y="2" width="6" height="11" rx="3" />  <path d="M5 10a7 7 0 0 0 14 0" />  <line x1="8" y1="21" x2="16" y2="21" />  <line x1="12" y1="17" x2="12" y2="21" /></svg></button>
            <button onClick={() => toggleWebcam()} className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-2 mb-2 rounded-md w-25"><svg class="h-6 w-6 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z" />  <circle cx="12" cy="13" r="4" /></svg></button>
            <button onClick={() => toggleScreenShare()} className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-2 mb-2 rounded-md w-25"><svg class="h-6 w-6 white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />  <polyline points="16 6 12 2 8 6" />  <line x1="12" y1="2" x2="12" y2="15" /></svg></button>
        </div>
    );
}

function MeetingView(props) {
    const [joined, setJoined] = useState(null);
    const [enlargedParticipant, setEnlargedParticipant] = useState(null); // State for the enlarged participant
    const { join, participants } = useMeeting({
        onMeetingJoined: () => {
            setJoined("JOINED");
        },
        onMeetingLeft: () => {
            props.onMeetingLeave();
        },
    });

    const joinMeeting = () => {
        setJoined("JOINING");
        join();
    };

    // const copyMeetingId = () => {
    //     navigator.clipboard.writeText(props.meetingId).then(() => {
    //         alert("Meeting ID copied to clipboard!");
    //     }).catch((err) => {
    //         console.error("Failed to copy meeting ID", err);
    //     });
    // };

    const handleParticipantClick = (participantId) => {
        setEnlargedParticipant(participantId); // Set the clicked participant as enlarged
    };

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    // Function to handle window resizing
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
  
    useEffect(() => {
      // Add event listener for window resizing
      window.addEventListener('resize', handleResize);
      
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <div className='text-white bg-gray-100 h-screen'>
            <div className="flex items-center justify-center">
                <div className="rounded-lg p-8 w-full">
                    {joined && joined === "JOINED" ? (
                        <div>
                            <div className="grid grid-cols-12">
                                <div className="col-span-12 lg:col-span-9 grid grid-cols-12 gap-2">
                                    {[...participants.keys()].map((participantId) => (
                                        <div className="col-span-12 lg:col-span-4">
                                            <div onClick={() => handleParticipantClick(participantId)} className={`cursor-pointer ${enlargedParticipant === participantId ? "z-50" : ""}`}>
                                                <ParticipantView
                                                    participantId={participantId}
                                                    key={participantId}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="col-span-12 lg:col-span-3">
                                    <Chat />
                                </div>
                            </div>
                            <Controls />
                            {enlargedParticipant && (
                                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center">
                                    <ParticipantView participantId={enlargedParticipant} height={`${windowHeight - 120}px`}/>
                                    <button onClick={() => setEnlargedParticipant(null)} className="absolute top-2 right-2 bg-red-600 text-white p-2 rounded-full"><svg class="h-8 w-8 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg></button>
                                </div>
                            )}
                        </div>
                    ) : joined && joined === "JOINING" ? (
                        <p className="text-center text-gray-900">Joining the meeting...</p>
                    ) : (
                        <div className="text-center">
                            <button
                                onClick={joinMeeting}
                                className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 mb-2 rounded-md w-25"
                            >
                                Join Meeting
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function LiveVideMeet() {
    const [meetingId, setMeetingId] = useState(null);
    const [userName, setUserName] = useState(""); // Store the dynamic username

    const getMeetingAndToken = async (id, name) => {
        const meetingId = id == null ? await createMeeting({ token: authToken }) : id;
        setMeetingId(meetingId);
        setUserName(name);
    };

    const onMeetingLeave = () => {
        setMeetingId(null);
    };

    return authToken && meetingId ? (
        <MeetingProvider
            config={{
                meetingId,
                micEnabled: true,
                webcamEnabled: false,
                name: userName || "Anonymous",
            }}
            token={authToken}
        >
            <Navbar meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
            <MeetingView meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
        </MeetingProvider>
    ) : (
        <JoinScreen getMeetingAndToken={getMeetingAndToken} />
    );
}

export default LiveVideMeet;
