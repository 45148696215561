import React, { useState, useEffect } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

const Chat = () => {
    const { sendChatMessage, messages, localParticipant } = useMeeting(); // Get the chat functions and local participant info
    const [chatMessage, setChatMessage] = useState(""); // Input for chat message
    const [chatHistory, setChatHistory] = useState([]); // To store chat history

    const handleSendMessage = () => {
        if (chatMessage.trim()) {
            sendChatMessage(chatMessage); // Send the chat message
            setChatMessage(""); // Clear the input
        }
    };

    // Update the chat history when new messages arrive
    useEffect(() => {
        const newMessages = Array.from(messages.values());
        setChatHistory(newMessages);
    }, [messages]);

    return (
        <div className="h-full ml-4">
            <div className="h-[500px] text-gray-900 border p-4 mt-5 lg:mt-0" style={{overflowY: "scroll" }}>
                {chatHistory.map((msg, index) => (
                    <div 
                        key={index} 
                        className={`mb-2 p-2 rounded-lg ${msg.senderId === localParticipant.id ? 'bg-green-100 text-right self-end' : 'bg-gray-100 text-left self-start'}`}>
                        <strong>{msg.senderId === localParticipant.id ? 'You' : msg.senderName}:</strong> {msg.text}
                    </div>
                ))}
            </div>

            <div className="mt-2 flex items-center space-x-1">
                <input
                    type="text"
                    className="w-full  p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:border-[#136a3b] bg-gray-100 text-sm text-gray-900"
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    placeholder="Type your message..."
                />

                <button
                    onClick={handleSendMessage}
                    className="flex items-center bg-[#136a3b] hover:bg-green-600 text-white p-2 rounded-full shadow-md transition duration-300">
                    <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  
                        <line x1="22" y1="2" x2="11" y2="13" />  
                        <polygon points="22 2 15 22 11 13 2 9 22 2" />
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Chat;
