import React, { useState } from 'react';

const JoinScreen = ({ getMeetingAndToken }) => {
    const [meetingId, setMeetingId] = useState(null);
    const [name, setName] = useState(""); // New state for username

    const onJoinClick = async () => {
        await getMeetingAndToken(meetingId, name); // Pass the meetingId and username to the parent component
    };

    const onCreateClick = async () => {
        await getMeetingAndToken(null, name); // When creating, meetingId is null, but we pass the username
    };

    return (
        <div className='bg-gray-900 text-white'>

            <div class="flex items-center justify-center h-screen">
                <div class="bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-md">
                    <h1 class="text-3xl font-bold mb-4 text-center text-white">Join a Meeting</h1>
                    <div class="mb-4">
                        <label for="meeting-id" class="block text-gray-400 font-medium mb-2">Meeting Name</label>
                        <input
                            type="text"
                            class="bg-gray-700 rounded-md py-2 px-3 w-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)} // Handle name input
                        />

                    </div>

                    <div class="mb-4">
                        <label for="meeting-id" class="block text-gray-400 font-medium mb-2">Meeting ID</label>
                        <input
                            type="text"
                            class="bg-gray-700 rounded-md py-2 px-3 w-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            placeholder="Enter Meeting Id"
                            onChange={(e) => setMeetingId(e.target.value)}
                        />

                    </div>
                    <button onClick={onJoinClick} class="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 mb-2 rounded-md w-full">Join</button>
                     <p className='pb-2 text-center'>or</p>
                    <button onClick={onCreateClick} class="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 mb-2 rounded-md w-full">Create Meeting</button>

                </div>
            </div>


        </div>
    );
}

export default JoinScreen